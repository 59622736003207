<template>
    <AdminToast />
    <div class='grid'>

        <div class='col-12 md:col-6 lg:col-3'>

            <div class='card p-6 h-full flex flex-column'>

                <div class='widget-header flex flex-wrap align-items-center justify-content-between pt-1 pb-1'>
                    <div class='header-left-section flex align-items-center justify-content-start mb-3 md:mb-0'>
                        <div class='profile relative'>
                            <img src='http://image.a1catering.com.tr/YoneticiProfil/ProfilDefault.png' width='100' class='border-circle border-3 border-solid' />
                        </div>
                    </div>
                </div>

                <span>
					<hr>
				</span>

                <span>

				<Button label='Yardım' icon='pi pi-question-circle' @click='visibleLeft = true' class='p-button-danger pr-3 pl-3 pt-2 pb-2 mr-2' />
                
                <a href="/#/YeniPersonelEklemeVideo" target="_blank">
                    <Button label='Video' icon='pi pi-play' class='p-button-help pr-3 pl-3 pt-2 pb-2' />
                </a>

				<Sidebar v-model:visible='visibleLeft' :baseZIndex='1000'>
					<h3>Personel Ekleme</h3>
					
                    <p class="text-lg">
						<b>Adı ve Soyadı:</b> Personelin kimlikte yer alan adı ve soyadını giriniz. Kimlikte yer alan bilginin aynı olması gerekmektedir. Yanlış karakter girilmesi ile maaş ödemelerinde sorun ile karşılaşabilirsiniz.<br>
						<br>
						<b>T.C. Kimlik Numarası:</b> Kimlikte yer alan 11 haneli T.C. kimlik numarasını girin. Bu personel zaten var uyarı alırsanız personel listesinden düzenleyebilir veya T.C. kimlik numarası ile projeye ekle fonksiyonu ile projede işe başlatabilirsiniz.<br>
						<br>
						<b>Doğum Tarihi:</b> Kimlikte yer alan doğum tarihini girin.<br>
						<br>
						<b>Cinsiyet:</b> Cinsiyet seçimi yapınız.<br>
						<br>      
					</p>

                    <span><hr></span>
                    <span class="text-red-500 font-bold">*</span> doldurulması zorunlu alanlardır.<br>

				</Sidebar>

				</span>

            </div>


        </div>

        <div class='col-12 md:col-6 lg:col-9'>

            <div class='card p-fluid p-6 h-full flex flex-column'>

                <div class='grid'>

                    <div class='col-12'>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Adı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='name' type='text' autocomplete='off' v-model='dataItem.name' />
                            </div>
                        </div>


                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Soyadı<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='surName' type='text' autocomplete='off' v-model='dataItem.surName' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>T.C. Kimlik Numarası<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <InputText id='identityNumber' type='text' autocomplete='off' maxlength='11' v-model='dataItem.identityNumber' />
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Doğum Tarihi<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Calendar id='birthday' dateFormat='dd.mm.yy' autocomplete='off' :showIcon='true' :showButtonBar='true' v-model='dataItem.birthday'></Calendar>
                            </div>
                        </div>

                        <div class='field grid mb-5'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'>Cinsiyet<span class="text-red-500 font-bold ml-2 mr-2">*</span></label>
                            <div class='col-12 md:col-10'>
                                <Dropdown id='gender' v-model='dataItem.gender' :options='genders' optionLabel='name' option-value='value' placeholder='Seçim Yapın'></Dropdown>
                            </div>
                        </div>

                        <div class='field grid'>
                            <label class='col-12 mb-2 md:col-2 md:mb-0'></label>
                            <div class='col-12 md:col-10'>
                                <Button id='save' label='Kaydet' icon='pi pi-save' @click='save' v-bind:disabled='isProcessing'></Button>
                            </div>
                        </div>


                    </div>

                </div>

            </div>

        </div>


    </div>
</template>

<script>

import { showErrorMessage, showValidationMessage } from '@/components/shared/toast/toastFunction';
import AdminToast from '@/components/shared/toast/adminToast';
import EmployeeService from '../../services/employeeService';
import { getUserId, normalizeDate } from '../common/commonConstantFunctions';

export default {
    _employeeService: null,
    components: { AdminToast },
    created() {
        this._employeeService = new EmployeeService();
    },
    async mounted() {
        this.dataItem.managerUserId = getUserId();
    },
    data() {
        return {
            isProcessing:false,
            display: false,
            visibleLeft: false,
            dataItem: {
                id: '',
                name: '',
                surName: '',
                isActive: '',
                insertDate: '',
                identityNumber: '',
                birthday: '',
                gender: '',
                education: '',
                lastSchool: '',
                militaryStatus: '',
                maritalStatus: '',
                foreignLanguage: '',
                childCount: '',
                address: '',
                cityId: '',
                townId: '',
                gsm: '',
                email: '',
                emergencyPersonName: '',
                emergencyPersonPhone: '',
                employeePhoto:'EmployeePhoto/ProfilDefault.png',
                managerUserId:0,
            },
            genders: [
                {
                    name: 'Erkek',
                    value: 'Erkek',
                },
                {
                    name: 'Kadın',
                    value: 'Kadın',
                },
            ],
        };
    },

    methods: {
        open() {
            this.display = true;
        },
        close() {
            this.display = false;
        },
        async save() {
            this.isProcessing = true;
            if (await this.validateForm()) {

                this.dataItem.birthday = normalizeDate(this.dataItem.birthday);

                let insertResponse = await this._employeeService.insertEmployee(this.dataItem);
                if (insertResponse.isSuccess) {
                    await this.$router.push({ name: 'editEmployee', params: { employeeId: insertResponse.data } });
                } else {
                    showErrorMessage(this, 'Çalışan Eklemede Sorun İle Karşılaşıldı');
                }
            }
            this.isProcessing = false;
        },
        async validateForm() {
            if (this.dataItem.name == '') {
                showValidationMessage(this, 'Çalışan Adı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.surName == '') {
                showValidationMessage(this, 'Çalışan Soyadı Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.identityNumber == '') {
                showValidationMessage(this, 'Çalışan T.C. Kimlik No Alanı Boş Bırakılamaz');
                return false;
            }

            let checkResponse = await this._employeeService.checkIdentityNumberIsAlreadyUsed(this.dataItem.identityNumber);
            if (checkResponse) {
                showValidationMessage(this, 'Bu T.C. Kimlik Numarası İle Kayıt Var');
                return;
            }

            if (this.dataItem.birthday == '') {
                showValidationMessage(this, 'Çalışan Doğrum Tarihi Alanı Boş Bırakılamaz');
                return false;
            }

            if (this.dataItem.gender == '') {
                showValidationMessage(this, 'Çalışan Cinsiyet Alanı Boş Bırakılamaz');
                return false;
            }

            return true;
        },
    },

};

</script>


<style scoped>

</style>
